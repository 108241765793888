import React, { useState } from 'react'
import BannerSectionCustom from '../containers/CustomContainers/Banner'
import Affiliates from '../containers/CustomContainers/Affiliates'
import FooterCustom from '../containers/CustomContainers/Footer'
import HeadermenuCustom from '../containers/CustomContainers/HeadermenuBs'
import ContactPoc from '../containers/CustomContainers/Contact'
import ComplexTextDescription from '../containers/CustomContainers/ComplexTextDescription'
import CookieContainer from '../containers/CustomContainers/CookieContainer'
import GlobalStyle from '../containers/CustomContainers/Common/global-styles'
import '../components/layout.css'
import Seo from '../components/seo'
import Fonts from '../containers/CustomContainers/Common/fonts'
import { graphql } from 'gatsby'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import Partners from '../containers/CustomContainers/Partners'

const PartnersAndResellers = ({ data }) => {
    const AppName = 'Partners and Resellers'
    const SharedData = data.sharedJson
    const partnersAndResellersJson = data.partnersAndResellersJson
    const [blocking, setBlocking] = useState(false)
    const [expanded, setExpanded] = useState(false)

    const partners = data.allPowerBiTilesPowerBiTilesPartnersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }

        return content
    })

    const affiliates = data.allPowerBiTilesPowerBiTilesResellersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content);
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }
        return content;
    })

    return (
        <BlockUi
            tag="div"
            blocking={blocking}
            message="Submiting your message"
            keepInView
        >
            <React.Fragment>
                <div>
                    <Fonts/>
                    <GlobalStyle/>
                    <HeadermenuCustom
                        data={partnersAndResellersJson.Header}
                        expanded={expanded}
                        expandToggle={newValue => setExpanded(newValue)}
                    />
                    <main onClick={() => setExpanded(false)}>
                        <Seo
                            title={partnersAndResellersJson.SEO.Title}
                            description={partnersAndResellersJson.SEO.Description}
                            favIconPath={partnersAndResellersJson.SEO.FavIconPath}
                            previewImage={partnersAndResellersJson.SEO.PreviewImage}
                            keywords={partnersAndResellersJson.SEO.Keywords}
                        />
                        <BannerSectionCustom data={partnersAndResellersJson.Banner}/>
                        <Affiliates data={affiliates} />
                        <ComplexTextDescription data={partnersAndResellersJson.ComplexTextDescription}/>
                        <ContactPoc
                            sharedData={SharedData.ContactUs}
                            data={partnersAndResellersJson.ContactUs}
                            blockUIChange={newValue => setBlocking(newValue)}
                            appName={AppName}
                        />
                        <Partners data={partners}/>
                    </main>
                    <FooterCustom data={partnersAndResellersJson.Footer}/>
                </div>
                <CookieContainer sharedData={SharedData.Cookie}/>
            </React.Fragment>
        </BlockUi>
    )
}

export const pageQuery = graphql`
    query partnersAndResellersPageQuery {
        allPowerBiTilesPowerBiTilesPartnersListItem(
            sort: {order: ASC, fields: [data___fields___ColumnOrder]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
        allPowerBiTilesPowerBiTilesResellersListItem(
            sort: {order: ASC, fields: [data___fields___Site___Url]}
        ) {
            nodes {
                internal {
                    content
                }
            }
        }
        sharedJson {
            Products {
                Items {
                    ProductsCardIcon
                    ProductsCardHeading
                    ProductsCardPara
                    ProductRoute
                }
            }
            ContactUs {
                SectionHeading
                SectionSubHeading
                ContactRight {
                    ContactRightHeading
                    ContactRightPara
                }
            }
            Cookie {
                CookieName
                CookieExpireDays
                SaveCookieOnDecline
                OkButtonMessage
                CookieMessage
            }
        }
        partnersAndResellersJson {
            SEO {
                Title
                Description
                FavIconPath
                PreviewImage
                Keywords
            }
            Header {
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems {
                    Menu
                    Href
                    LocalLink
                }
            }
            Banner {
                BannerHeading
                BannerDescription
                BannerDescriptionExtra
                BtnText {
                    Title
                    LocalLink
                    Items {
                        Name
                        Href
                    }
                    MultiSelection
                }
                PathToImage
            }
            ComplexTextDescription {
                BackgroundGrey
                Heading
                Border
                Descriptions {
                    Header
                    Body
                }
            }
            Footer {
                FooterLogo
                Items
                FooterCol2 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                        LocalLink
                    }
                }
                FooterCol3 {
                    FooterTitle
                    FooterUl {
                        FooterMenu
                        Href
                    }
                }
                FooterCol4 {
                    FooterTitle
                    FooterAddWrapper {
                        FooterAddImg
                        FooterAddText
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                SocialLinks {
                    FacebookLink
                    TwitterLink
                    LinkedinLink
                    InstagramLink
                }
            }
            ContactUs {
                BackgroundGrey
            }
        }
    }
`

export default PartnersAndResellers
