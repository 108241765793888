import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Row, Col, Card, Form } from 'react-bootstrap';
import './custom.css';


const Affiliates = ({ data }) => {
    const [resellerData, setResellerData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const isSubstring = (property, filter) => {
        return filter.length === 0 ? false : property.toUpperCase().includes(filter);
    }

    const getFilterData = (filterNames) => {
        let filters = [];

        filterNames.forEach(filterName => {
            let filter = document.getElementById(filterName);
            filters.push(
                {
                    Name: filterName,
                    Value: filter.value.toUpperCase()
                });
        });

        return filters;
    }

    const propertyMatchesFilter = (property, filter) => {
        if (filter.length === 0) return true;
        else if (isSubstring(property, filter)) return true;
        else return false;
    }

    const filter = () => {
        let cards = [...resellerData];
        let filterNames = ["TitleFilter", "CountryFilter", "RoleFilter"];
        const filters = getFilterData(filterNames);

        setIsLoading(true);

        cards.forEach(card => {
            let titleMatchesFilter = propertyMatchesFilter(card.Title, filters[0].Value);
            let countryMatchesFilter = propertyMatchesFilter(card.Country, filters[1].Value);
            let roleMatchesFilter = propertyMatchesFilter(card.Role, filters[2].Value);
            card.Hidden = !(titleMatchesFilter && countryMatchesFilter && roleMatchesFilter);
        });

        setResellerData(cards);

        setIsLoading(false);
    }

    const setOnKeyUpEventHandlers = () => {
        document.getElementById('TitleFilter').onkeyup = function () { filter() };
        document.getElementById('CountryFilter').onkeyup = function () { filter() };
        document.getElementById('RoleFilter').onkeyup = function () { filter() };
    }

    function createAffiliate(item) {
        return {
            Id: item.id,
            Title: item.fields.Title,
            Logo: item.fields.Logo,
            Role: item.fields.Role,
            Country: item.fields.Country,
            Url: item.fields.Site.Url,
            ExtraInfo: item.fields.ExtraInfo,
            Hidden: false
        };
    }

    useEffect(() => {
        setIsLoading(true);
        setResellerData([]);
        data.map((item) => {
            const affiliate = createAffiliate(item);
            resellerData.push(affiliate);
        });
        setResellerData(
            resellerData.sort((a, b) => a.Role.toUpperCase().localeCompare(b.Role.toUpperCase()) || a.Title.toUpperCase().localeCompare(b.Title.toUpperCase()))
        )
        setOnKeyUpEventHandlers();
        setIsLoading(false);
    }, []);

    const addResellerEventListeners = (card) => {
        let roleText = card.getElementsByClassName('role-text')[0];
        let cardBody = card.getElementsByClassName('card-body')[0];
        let cardUrl = card.getElementsByClassName('url')[0];

        roleText.style.backgroundColor = '#6028fa';
        card.addEventListener("mouseover", function () {
            cardBody.style.backgroundColor = '#6028fa';
            cardBody.style.color = 'white';
            roleText.style.backgroundColor = 'white';
            roleText.style.color = '#6028fa';
            cardUrl.style.display = 'flex';
            cardUrl.style.backgroundColor = '#6028fa';
            cardUrl.style.color = 'white';
        }, false);
        card.addEventListener("mouseout", function () {
            cardBody.style.backgroundColor = '#f5f5f5';
            cardBody.style.color = 'hsla(0, 0%, 0%, 0.8)';
            roleText.style.backgroundColor = '#6028fa';
            roleText.style.color = 'white';
            cardUrl.style.display = 'none';
            cardUrl.style.backgroundColor = '#6028fa';
            cardUrl.style.color = 'white';
        }, false);
    }

    const addPartnerEventListeners = (card) => {
        let roleText = card.getElementsByClassName('role-text')[0];
        let cardBody = card.getElementsByClassName('card-body')[0];
        let cardUrl = card.getElementsByClassName('url')[0];

        card.addEventListener("mouseover", function () {
            cardBody.style.backgroundColor = '#ef5350';
            cardBody.style.color = 'white';
            roleText.style.backgroundColor = 'white';
            roleText.style.color = '#ef5350';
            cardUrl.style.display = 'flex';
            cardUrl.style.backgroundColor = '#ef5350';
            cardUrl.style.color = 'white';
        }, false);
        card.addEventListener("mouseout", function () {
            cardBody.style.backgroundColor = '#f5f5f5';
            cardBody.style.color = 'hsla(0, 0%, 0%, 0.8)';
            roleText.style.backgroundColor = '#ef5350';
            roleText.style.color = 'white';
            cardUrl.style.display = 'none';
            cardUrl.style.backgroundColor = '#ef5350';
            cardUrl.style.color = 'white';
        }, false);

    }

    useEffect(() => {
        if (isLoading) return;
        let cards = document.getElementsByClassName('card');
        for (let i = 0; i < cards.length; i++) {
            let roleText = cards[i].getElementsByClassName('role-text')[0];
            if (roleText.innerText === 'Reseller') {
                addResellerEventListeners(cards[i]);
            }
            else {
                addPartnerEventListeners(cards[i]);
            }
        }
    }, [isLoading])

    return (
        <div id="partnersAndResellersContainer">
            <div class="intro">
                <h3 className='text-center pl-3 pr-3'>Find an affiliate near you</h3>
                <p className='text-center pl-3 pr-3'>Find the right DevScope affiliate near you and get in touch with them to start improving your business with our solutions:</p>
                <Row className='align-items-center justify-content-center mx-5 filters'>
                    <Col className="pt-2" sm={12} md={4}>
                        <Form.Control type='text' id='TitleFilter' placeholder='Search by title' />
                    </Col>
                    <Col className="pt-2" sm={12} md={4}>
                        <Form.Control type='text' id='CountryFilter' placeholder='Search by country' />
                    </Col>
                    <Col className="pt-2" sm={12} md={4}>
                        <Form.Control type='text' id='RoleFilter' placeholder='Search by role' />
                    </Col>
                </Row>
            </div>
            <Row xs={1} sm={1} md={2} lg={3} xl={4} className='wrapper justify-content-center align-items-center'>
                {
                    !isLoading &&
                    resellerData.map((item) => {
                        const imageUrl = `..${item.Logo.serverRelativeUrl}`;
                        if (item && item.Logo) {
                            return (
                                !item.Hidden ?
                                    <Col className="d-flex justify-content-center align-items-center">
                                        <Card id={item.Id} className='my-3'>
                                            <a href={item.Url}>
                                                <Card.Img variant='top' className='image' src={imageUrl} />
                                            </a>
                                            <Row className='role'>
                                                <Card.Text className='role-text'>{item.Role}</Card.Text>
                                            </Row>
                                            <Card.Body className='body'>
                                                <Card.Title>{item.Title}</Card.Title>
                                                <Card.Text className='country-text'>{item.Country}</Card.Text>
                                                <Card.Text className='extra-info'>{item.ExtraInfo}</Card.Text>
                                            </Card.Body>
                                            <Row className="url justify-content-end align-items-center">
                                                <Card.Text className="url-text">
                                                    <a href={item.Url}>
                                                        {'Visit website >'}
                                                    </a>
                                                </Card.Text>
                                            </Row>
                                        </Card>
                                    </Col> : null
                            )
                        }
                    })
                }
            </Row>
        </div>
    );
}

export default Affiliates;