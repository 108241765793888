import React from 'react';
import {
    TestimonialsSection, HeadingLayout, Heading, SliderOuterLayout,
    TestimonialsOuterWrapper, SliderInnerLayout, TestimonialLayout
} from './testimonials.style';
import { Fragment, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ComplexTextDescription = ({ data }) => {
    const color = data.BackgroundGrey ? "#f9fafc" : "#ffffff";

    return (
        <TestimonialsSection id="simpleTextContainer" className="complex-text-description" style={{ background: color }}>
            <HeadingLayout>
                <Heading>
                    {data.Heading}
                </Heading>
                <img src={data.Border} alt="" />
            </HeadingLayout>

            <SliderOuterLayout>
                <Container>
                    <TestimonialsOuterWrapper>
                        <SliderInnerLayout>
                            <TestimonialLayout>
                                <p>{data.Descriptions.map((item) => {
                                    return (
                                        <div>
                                            <h4 class="bold-header">{item.Header}</h4>
                                            <p>{item.Body}</p>
                                            <br />
                                        </div>
                                    )
                                })}</p>
                            </TestimonialLayout>
                        </SliderInnerLayout>
                    </TestimonialsOuterWrapper>
                </Container>
            </SliderOuterLayout>
        </TestimonialsSection>
    );
}

export default ComplexTextDescription;