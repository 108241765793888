import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading} from '../Common/common.style';
import {LeftArrowAlt} from '@styled-icons/boxicons-regular/LeftArrowAlt';
import {RightArrowAlt} from '@styled-icons/boxicons-regular/RightArrowAlt';

import {QuoteLeft} from '@styled-icons/fa-solid/QuoteLeft';
import {QuoteRight} from '@styled-icons/fa-solid/QuoteRight';

export const TestimonialsSection = styled.section`
    padding:100px 0px 100px;

    @media ${device.tablet}{
        padding:80px 0px 80px;
    }
`;
export const HeadingLayout = styled.div`
    margin-bottom:40px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    text-align:center;
`;



export const SliderOuterLayout = styled.div`
    position:relative;
`;

export const LeftArrowLayout = styled.div`
    border-right:1px solid #1565c0;
    border-bottom:1px solid #1565c0;
    border-top:1px solid #1565c0;
    border-left:1px solid #1565c000;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    position:absolute;
    top:calc(50% - 25px);
    z-index:1;
`;

export const LeftArrow = styled(LeftArrowAlt)`
    width:40px;
    color:#1565c0;
    margin: 5px 5px 5px 10px;
    cursor:pointer;
`;

export const TestimonialsOuterWrapper = styled.div`

`;

export const SliderInnerLayout = styled.div`
    outline:0;
`;

export const TestimonialLayout = styled.div`
    margin:auto;

    p{
        margin-bottom:0px;
        padding:0px 50px;
    }
    .bold-header{
        padding:0px 50px;
    }
`;

export const QuotesTop = styled(QuoteLeft)`
    width:40px;
    color:#1565c0;
    margin-bottom:-10px;
`;



export const QuotesHolder = styled.div`
    text-align:right;
`;

export const QuotesBottom = styled(QuoteRight)`
    width:40px;
    color:#1565c0;
    margin-top:-10px;
`;

export const AuthorLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:10px;

    .ProPic{
        width:60px;
        flex-shrink:0;
        border-radius:100%;
    }
`;

export const TextLayout = styled.div`
    margin-left:10px;

    h6{
        text-align:left;
        line-height:1;
        margin-bottom:5px;
    }
    p{
        text-align:left;
        margin-bottom:0px;
        line-height:1;
        padding: 0;
    }
`;



export const RightArrowLayout = styled.div`
    border-left:1px solid #1565c0;
    border-bottom:1px solid #1565c0;
    border-top:1px solid #1565c0;
    border-right:1px solid #1565c000;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    position:absolute;
    top:calc(50% - 25px);
    right:0;
`;

export const RightArrow = styled(RightArrowAlt)`
    width:40px;
    color:#1565c0;
    margin: 5px 5px 5px 10px;
    cursor:pointer;
`;
